// @flow

import * as LDClient from "launchdarkly-js-client-sdk";

import ActionTypes from "@actionTypes";
import FeatureFlags from "../constants/FeatureFlags";
// import Config from "@Config";

// List of launch darkly feature flags to check against
const launchDarklyFeatures = [
    { name: FeatureFlags.DISPLAY_TEST_GROUP_BADGE, default: false },
    { name: FeatureFlags.ENABLE_TEST_STORES, default: false },
    { name: FeatureFlags.ENABLE_JS_EARN_AT_COUNTER, default: false },
    { name: FeatureFlags.IS_JC_LOYALTY_DISABLED, default: false },
    { name: FeatureFlags.IS_ACCOUNT_CREATION_DISABLED, default: false },
    { name: FeatureFlags.IS_REWARD_WALLET_DISABLED, default: false },
    { name: FeatureFlags.IS_CM_MVP_JCL_ENABLED, default: true },
    { name: FeatureFlags.IS_CM_MVP_MISC_ENABLED, default: true },
    { name: FeatureFlags.IS_CLUTCH_ENABLED, default: true },
];

const buildActiveFlags = (): Object => {
    const activeFlags = {};
    launchDarklyFeatures.forEach(variation => {
        activeFlags[variation.name] = window.launchDarklyClient.variation(
            variation.name,
            variation.default
        );
    });
    console.table(activeFlags)
    return activeFlags;
};

export const init = (): any => (dispatch: any, getState: any): any => {
    return new Promise((resolve: any) => {
        const { session } = getState();

        window.launchDarklyClient = LDClient.initialize(
            window.launch_darkly_key,
            {
                // key: user && user.key ? user.key : session.sessionId,
                key: session.sessionId,
                custom: {
                    SessionId: session.sessionId,
                    TestGroup: session.testGroup,
                    ClientPlatform: "web"
                }
            }
        );

        window.launchDarklyClient.on("change", () => {
            dispatch({
                type: ActionTypes.SET_LAUNCH_DARKLY_FLAGS,
                flags: buildActiveFlags()
            });
            resolve();
        });

        window.launchDarklyClient.on("ready", () => {
            dispatch({
                type: ActionTypes.SET_LAUNCH_DARKLY_FLAGS,
                flags: buildActiveFlags()
            });
            resolve();
        });
    });
};
