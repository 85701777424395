// @flow

import * as React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { ReactForm, ReactFormField } from "@adrenalin/react-form";
import moment from "moment";
import DateService from "@utils/DateService";
import { push } from "@lagunovsky/redux-react-router";

import Actions from "@actions";
import Config from "@Config";
import AddressAPI from "@apis/AddressAPI";
import { formatAddressComponents, responsive } from "@utils";
import QuickSearch from "@components/molecules/QuickSearch";
import RadioButton from "@components/atoms/RadioButton";
import Button from "@components/atoms/Button";
import * as GoogleAnalytics from "@utils/GoogleAnalytics";
import TextButton from "@components/atoms/TextButton";

import MapPin from "@assets/images/map-pin.svg"
import MapPinTicked from "@assets/images/map-pin-ticked.svg"

import {
    type SavedAddressType,
    type QuickSearchResultType
} from "@SharedTypes";

type PropsType = {
    userLatLng: { lat: string, lng: string },
    savedAddresses: {
        isFetching: boolean,
        addresses: SavedAddressType[]
    },
    device: any,
    addSavedAddress: boolean,
    editSavedAddress: boolean,
    stores: any,
    modalId?: string,
    deliveryTimes: {
        from: string,
        to: string,
        increments: string,
        offset: ?string
    },
    deliveryTimeFrame: string,
    getGeoStatus: () => Promise<any>,
    setDeliveryDetails: (selectedStore: string, deliveryTime: string) => any,
    setDeliveryLocation: (location: any) => any,
    setSelectedStore: (store: any) => any,
    setOrderMethod: (method: string) => any,
    push: (path: string) => any,
    closeGlobalModal: (modalId: string, successs: boolean) => any,
    openAlertModal: (config: any) => any,
    closeAlertModal: () => any,
    setDeliveryTime: (time: string) => any,
    onSuccess?: (details?: any) => void,
    toPath?: string,
    session: any,
    preloadedAddress: ?QuickSearchResultType,
    onDelete: ?() => any
};

type StateType = {
    inputValue: string,
    searching: boolean,
    searchResults: QuickSearchResultType[],
    selectedRecord: ?QuickSearchResultType,
    deliveryTimeType: string,
    hasResults: boolean,
    submitLoading: boolean,
    serverError: string,
    toPath?: string,
    showPin: boolean
};

class AddressLocator extends React.Component<PropsType, StateType> {
    state = {
        toPath: this.props.toPath,
        inputValue: this.props.addSavedAddress
            ? ""
            : _.get(this, "props.preloadedAddress.full_address", ""),
        searching: false,
        searchResults: [],
        selectedRecord: this.props.addSavedAddress
            ? null
            : _.get(this, "props.preloadedAddress", null),
        deliveryTimeType:
            _.get(this, "props.session.deliveryTime", "asap") === "asap"
                ? "asap"
                : "later",
        hasResults: false,
        submitLoading: false,
        serverError: "",
        showPin: false
    };

    cardEl = React.createRef<HTMLDivElement>();
    mapEl = React.createRef<HTMLDivElement>();
    pinEl = React.createRef<HTMLDivElement>();
    initMapTimeout;
    map = null;
    geocoder = new window.google.maps.Geocoder();
    zoomLevel = 15;
    mapListeners = [];

    // LIFECYCLE METHODS
    // -----------------

    componentDidMount() {
        // delay init of map to allow modal to transition in smoothly
        this.initMapTimeout = setTimeout(this.initMap.bind(this), 1000);
    }

    componentWillUnmount() {
        clearTimeout(this.initMapTimeout);
        window.removeEventListener("resize", this.handleWindowResize);
        this.mapListeners.forEach((listener: any) => {
            window.google.maps.event.removeListener(listener);
        });
    }

    initMap() {
        const map = new window.google.maps.Map(this.mapEl.current, {
            center: this.props.addSavedAddress
                ? this.props.userLatLng
                : _.get(
                      this,
                      "props.preloadedAddress.coords",
                      this.props.userLatLng
                  ),
            zoom: this.zoomLevel,
            mapTypeControl: false,
            zoomControl: this.props.editSavedAddress ? false : true,
            fullscreenControl: false,
            streetViewControl: false,
            gestureHandling: this.props.editSavedAddress
                ? "none"
                : "cooperative",
            draggableCursor: this.props.editSavedAddress
                ? "default"
                : undefined,
            styles: [
                {
                    featureType: "poi",
                    stylers: [{ visibility: "off" }]
                }
            ]
        });
        this.map = map;
        this.mapListeners = [
            map.addListener("zoom_changed", this.offsetMapCenter),
            map.addListener("dragend", this.handleMapDragEnd)
        ];
        window.addEventListener("resize", this.handleWindowResize);

        // delay to make sure offset is caclulated after the map is setup
        setTimeout(() => {
            this.positionPin();
            this.offsetMapCenter();
            this.setState({ showPin: true });
        }, 500);
    }

    // HELPER METHODS
    // --------------

    /** Positions the pin at the appropriate spot on the map */
    positionPin = () => {
        if (document.getElementsByClassName("gm-style-pbt").length > 0) {
            document.getElementsByClassName(
                "gm-style-pbt"
            )[0].style.paddingTop = `40px`;
            if (responsive.isMobileBreakpoint()) {
                document.getElementsByClassName(
                    "gm-style-pbt"
                )[0].style.paddingLeft = `0`;
            } else {
                if (this.cardEl.current) {
                    document.getElementsByClassName(
                        "gm-style-pbt"
                    )[0].style.paddingLeft = `${this.cardEl.current.clientWidth}px`;
                }
            }
        }
        if (this.pinEl.current) {
            const pin = this.pinEl.current;
            if (responsive.isMobileBreakpoint()) {
                pin.style.left = `50%`;
            } else {
                pin.style.left = `calc(50% + ${this.getOffsetFromCenterOfMap()}px)`;
            }
        }
    };

    search = async (): any => {
        if (this.state.inputValue.trim()) {
            this.setState({ searching: true });
            const response = await AddressAPI.search(
                this.state.inputValue.trim()
            );
            this.setState({
                searchResults: response.result,
                searching: false,
                hasResults: true
            });
        }
    };

    searchDebounced = _.debounce(this.search, Config.quickTypeDelay);

    /**
     * Pans the map by the offset amount if required
     */
    offsetMapCenter = () => {
        // Don't do anything on mobile layout
        if (responsive.isMobileBreakpoint()) return;

        if (this.map && this.mapEl.current && this.cardEl.current) {
            const map = this.map;
            const offset = -this.getOffsetFromCenterOfMap();
            const newZoom = Number(map.getZoom());
            if (this.zoomLevel !== newZoom) {
                if (this.zoomLevel < newZoom) {
                    // Zoomed in
                    map.panBy(offset * -1, 0);
                } else {
                    // Zoomed out
                    map.panBy(offset * 0.5, 0);
                }
                this.zoomLevel = newZoom;
            } else {
                map.panBy(offset, 0);
            }
        }
    };

    formatGeocodedAddressComponents = (
        adressComponents: Array<any>
    ): string => {
        const componentTypes = [
            "street_number",
            "route",
            "locality",
            "administrative_area_level_1", // State
            "postal_code"
        ];
        const components = componentTypes.map((type: string): string => {
            const comp = adressComponents.find((comp: any): boolean => {
                return comp.types.includes(type);
            });
            return _.get(
                comp,
                type === "administrative_area_level_1"
                    ? "short_name"
                    : "long_name",
                ""
            );
        });
        return components.join(" ");
    };

    geocodeAddress = (geocodeRequest: any): Promise<any> => {
        return new Promise((resolve: any, reject: any) => {
            this.geocoder.geocode(
                geocodeRequest,
                (results: any, status: string) => {
                    if (status === "OK") {
                        if (results[0]) {
                            resolve({
                                formatted_address: this.formatGeocodedAddressComponents(
                                    results[0].address_components
                                ),
                                lat: results[0].geometry.location.lat(),
                                lng: results[0].geometry.location.lng()
                            });
                        }
                    }
                    reject({ error: status });
                }
            );
        });
    };

    /**
     * Runs a search based on the passed in coords,
     * If not coords are passed - it will use the current devices coords
     */
    searchFromCoords = async (coords: any): any => {
        this.setMapCenter(coords);
        this.setState({
            searching: true,
            selectedRecord: null
        });
        const { formatted_address } = await this.geocodeAddress({
            location: coords
        });
        this.setState({ inputValue: formatted_address });
        await this.search();
    };

    createFormattedAddres = (result: QuickSearchResultType): string => {
        const { address_line, locality, state, postcode } = result;
        const addressComponents = [
            [address_line],
            [locality],
            [state, String(postcode)]
        ];
        const formattedAddress = formatAddressComponents(addressComponents);
        return formattedAddress;
    };

    // RENDER METHODS
    // --------------

    renderDeliveryTimeFields = (): React.Node => {
        const { from, to, increments, offset } = this.props.deliveryTimes;
        const startTime = moment(from, "HH:mm:ss");
        const endTime = moment(to, "HH:mm:ss");

        // Determine how much to increment by
        const incrementMoment = moment(increments, "HH:mm:ss");
        const addHours = incrementMoment.format("HH");
        const addMinutes = incrementMoment.format("mm");
        const addSeconds = incrementMoment.format("ss");

        const startOffset = moment.duration(offset);

        // Create 30m intervals between start and end delivery times
        // Ensure they occur after the current date
        let intervals = [];
        for (
            let current = startTime;
            current <= endTime;
            current = current
                .add(addHours, "hours")
                .add(addMinutes, "minutes")
                .add(addSeconds, "seconds")
        ) {
            // DT: Use user time here for rendering future delivery time
            if (current > DateService.now().add(startOffset)) {
                intervals.push({
                    value: current.toISOString(true),
                    display: current.format("h:mma")
                });
            }
        }

        let defaultTime = _.find(intervals, {
            value: _.get(this, "props.session.deliveryTime")
        });
        if (defaultTime) {
            defaultTime = defaultTime.value;
        } else {
            defaultTime = null;
        }

        return (
            <div>
                <div className="address-locator__time-select-form">
                    <div className="radio-buttons">
                        <ReactFormField
                            checked={this.state.deliveryTimeType === "asap"}
                            name="delivery-time-type"
                            type="radio"
                            label="ASAP"
                            value="asap"
                            fieldComponent={RadioButton}
                            onChange={this.handleDeliveryTimeTypeChange}
                        />
                        {intervals.length ? (
                            // <Tooltip
                            //     identifier="order-for-later"
                            //     title="Order Later"
                            //     message={`Want to order ahead of time? Select later and let us know when you'd like us to deliver.`}
                            //     position="bottom"
                            //     locked={true}
                            // >
                            <ReactFormField
                                checked={
                                    this.state.deliveryTimeType === "later"
                                }
                                name="delivery-time-type"
                                type="radio"
                                label="Later Today"
                                value="later"
                                fieldComponent={RadioButton}
                                onChange={this.handleDeliveryTimeTypeChange}
                            />
                        ) : (
                            // </Tooltip>
                            <div className="address-locator__time-select-error">
                                <p>
                                    Order for later is currently not available
                                </p>
                            </div>
                        )}
                    </div>
                    {this.state.deliveryTimeType === "later" &&
                    intervals.length ? (
                        <ReactFormField
                            type="select"
                            name="delivery-time"
                            id="delivery-time"
                            className={`address-locator__select-delivery-time`}
                            label="Time"
                            defaultValue={defaultTime}
                        >
                            {intervals.map(
                                (interval: any, index: number): any => (
                                    <option
                                        value={interval.value}
                                        key={interval.value}
                                    >
                                        {interval.display}
                                    </option>
                                )
                            )}
                        </ReactFormField>
                    ) : null}
                </div>
            </div>
        );
    };

    // GETTERS/SETTERS
    // ---------------

    /**
     * Calculates the pixel offset from the center of the map to
     * where the pin needs to be located
     */
    getOffsetFromCenterOfMap = (): number => {
        if (this.mapEl.current && this.cardEl.current) {
            const mapEl = this.mapEl.current;
            const cardEl = this.cardEl.current;
            const offsetFromRightOfCard =
                (mapEl.clientWidth - cardEl.clientWidth) / 2;
            const offsetFromMapCenter =
                mapEl.clientWidth / 2 - offsetFromRightOfCard;
            return offsetFromMapCenter;
        }
        return 0;
    };

    /**
     * Gets the center of the map + some pixel offset
     * Original code sourced from https://stackoverflow.com/a/10666030/3304514
     */
    getOffsetCenter(
        latlng: number,
        offsetx: number = 0,
        offsety: number = 0
    ): any {
        if (this.map) {
            try {
                const map = this.map;
                var scale = Math.pow(2, map.getZoom());

                var worldCoordinateCenter = map
                    .getProjection()
                    .fromLatLngToPoint(latlng);
                var pixelOffset = new window.google.maps.Point(
                    offsetx / scale || 0,
                    offsety / scale || 0
                );

                var worldCoordinateNewCenter = new window.google.maps.Point(
                    worldCoordinateCenter.x - pixelOffset.x,
                    worldCoordinateCenter.y + pixelOffset.y
                );

                return map
                    .getProjection()
                    .fromPointToLatLng(worldCoordinateNewCenter)
                    .toJSON();
            } catch {}
        }
    }

    /**
     * Gets the centerpoint of the map - Will add offset if required
     */
    getMapCenter = (): { lat: number, lng: number } => {
        if (this.map && this.map.getCenter()) {
            const map = this.map;
            if (responsive.isMobileBreakpoint()) {
                return map.getCenter().toJSON();
            } else {
                return this.getOffsetCenter(
                    map.getCenter(),
                    -this.getOffsetFromCenterOfMap()
                );
            }
        }
        return { lat: 0, lng: 0 };
    };

    /**
     * Sets a new cetnerpoint for the map and then calls offsetMapCenter
     */
    setMapCenter = (coords: any) => {
        if (this.map) {
            const map = this.map;
            map.setCenter(coords);
            this.offsetMapCenter();
        }
    };

    /**
     * Determines the time to send to the endpoint if the user has chosen
     * asap for the delivery time.
     * It is calculated as:
     * current_time + default_expected_time_frame from the app endpoint
     */
    getAsapDeliveryTime = (): string => {
        const deliveryTimeFrame = moment(
            this.props.deliveryTimeFrame,
            "HH:mm:ss"
        );
        const addHours = deliveryTimeFrame.format("HH");
        const addMinutes = deliveryTimeFrame.format("mm");
        const addSeconds = deliveryTimeFrame.format("ss");
        // DT: this passes the timezone with user device time to JE. No need to use StoreUtils.selectedStoreNow()
        return DateService.now()
            .add(addHours, "hours")
            .add(addMinutes, "minutes")
            .add(addSeconds, "seconds")
            .toISOString(true);
    };

    // EVENT HANDLERS
    // --------------

    handleWindowResize = () => {
        this.positionPin();
    };

    handleDeliveryTimeTypeChange = (event: any): any => {
        GoogleAnalytics.logEvent({
            category: "OrderHome",
            action: "AddressSearch",
            label: event.target.value === "asap" ? "ASAP" : "Later"
        });
        this.setState({ deliveryTimeType: event.target.value });
    };

    handleSearchInputChange = (
        event: SyntheticInputEvent<HTMLInputElement>
    ) => {
        const { value } = event.target;
        this.setState({
            inputValue: value,
            selectedRecord: null,
            hasResults: false
        });
        if ([...value].length > 3) {
            this.searchDebounced();
        } else {
            this.setState({ searchResults: [] });
        }
    };

    handleResultSelect = async (
        result: QuickSearchResultType,
        isSavedAddress: boolean = false
    ): any => {
        // Save addressId into state

        result.isSavedAddress = isSavedAddress;

        this.setState({
            selectedRecord: result,
            searchResults: [],
            hasResults: false
        });

        // Get the address for this result
        const formattedAddress = this.createFormattedAddres(result);

        // Fill out the search field
        this.setState({ inputValue: isSavedAddress ? "" : formattedAddress });

        // Geocode the address
        const { lat, lng } = await this.geocodeAddress({
            address: formattedAddress
        });

        // Center the map over returned lat lng
        this.setMapCenter({ lat, lng });
    };

    handleUseLocationClick = async (): Promise<any> => {
        try {
            this.setState({ searching: true });
            await this.props.getGeoStatus();
            const { userLatLng } = this.props;
            this.searchFromCoords(userLatLng);
        } catch (error) {
            this.setState({ searching: false });
            console.error(error.message);
        }
    };

    handleMapDragEnd = (event: any) => {
        const coords = this.getMapCenter();
        this.searchFromCoords(coords);
    };

    handleSearchAgainClick = () => {
        this.setState({ selectedRecord: null });
        this.search();
    };

    handleFormSubmit = async (data: any): Promise<any> => {
        const { selectedRecord } = this.state;
        if (selectedRecord) {
            // Determine the delivery time for local/redux uses
            const timeToStoreInRedux =
                data["delivery-time-type"] === "asap"
                    ? "asap"
                    : data["delivery-time"];

            // Determine the datetime to send to the endpoint
            const timeToSendToEndpoint =
                data["delivery-time-type"] === "asap"
                    ? this.getAsapDeliveryTime()
                    : data["delivery-time"];
            // Handle submit for adding a new saved address
            if (this.props.addSavedAddress || this.props.editSavedAddress) {
                if (this.props.onSuccess) {
                    this.props.onSuccess({
                        ...data,
                        selectedRecordId: selectedRecord.record_id,
                        selectedAddressId: selectedRecord.address_id
                    });
                }
            }

            // Handle submit for address locator
            else {
                GoogleAnalytics.logEvent({
                    category: "OrderHome",
                    action: "AddressSearch",
                    label: "Next"
                });

                // Submit request
                try {
                    this.setState({ submitLoading: true });
                    const result = await AddressAPI.getDeliveryStore(
                        selectedRecord.record_id,
                        timeToSendToEndpoint
                    );
                    this.setState({ submitLoading: false });

                    const store = _.get(this, "props.stores.list").find(
                        (s: any): boolean => s.store_id === result[0].store_id
                    );

                    // store.store_timezone_id = "W. Australia Standard Time"; // DEBUG

                    if (store && this.map) {
                        // Save everything into session
                        const locationWithCoords = {
                            ...selectedRecord,
                            coords: this.getMapCenter()
                        };
                        this.props.setDeliveryLocation(locationWithCoords);
                        this.props.setOrderMethod("delivery");
                        this.props.setDeliveryTime(timeToStoreInRedux);
                        this.props.setSelectedStore(store);
                        // Use a passed in callback or redirect to the menu
                        if (this.props.onSuccess) {
                            this.props.onSuccess();
                        } else {
                            // Close the modal
                            this.props.closeGlobalModal(
                                this.props.modalId || "",
                                true
                            );

                            // Redirect the user to the menu route
                            this.props.push(
                                this.state.toPath ||
                                    `${Config.routes.menu}/${store.store_id}`
                            );
                        }
                    }
                } catch (error) {
                    // Recieved an error from the server
                    this.setState({ submitLoading: false });
                    console.error(error);
                    this.props.openAlertModal({
                        title: error.title || "Something went wrong",
                        body: (
                            <React.Fragment>
                                <p>{error.friendly_message}</p>
                            </React.Fragment>
                        ),
                        confirmText: "OK",
                        onConfirm: () => {
                            this.props.closeAlertModal();
                        }
                    });
                }
            }
        }
    };

    render(): React.Node {
        return (
            <div className="address-locator-wrap">
                <ReactForm
                    autoComplete="off"
                    onSuccess={this.handleFormSubmit}
                    disableSubmitUntilValid={true}
                >
                    <div className="address-locator">
                        <div className="address-locator__header">
                            <h3 className="heading-3 address-locator__title">
                                {this.props.addSavedAddress
                                    ? "New delivery address"
                                    : this.props.editSavedAddress
                                    ? "Edit your address details"
                                    : "When & where would you like your delivery?"}
                            </h3>
                            {this.props.addSavedAddress ? (
                                <p className="page-intro address-locator__copy">
                                    Order delivery faster, save your address
                                    details for next time.
                                </p>
                            ) : this.props.editSavedAddress ? (
                                <p className="page-intro address-locator__copy">
                                    Update your delivery notes or address
                                    nickname
                                </p>
                            ) : (
                                this.renderDeliveryTimeFields()
                            )}
                        </div>
                        <div className="address-locator__map-container">
                            <div className="address-locator__map-container-mobile">
                                <div
                                    className="address-locator__map"
                                    ref={this.mapEl}
                                />
                                <div
                                    className={`address-locator__pin ${
                                        this.state.showPin ? "" : "hide"
                                    }`}
                                    ref={this.pinEl}
                                >
                                    <img
                                        src={
                                            this.props.addSavedAddress &&
                                            this.state.selectedRecord
                                                ? MapPinTicked
                                                : MapPin
                                        }
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div
                                className="address-locator__card"
                                ref={this.cardEl}
                            >
                                <div className="address-locator__card-inner">
                                    {this.state.selectedRecord &&
                                    this.props.addSavedAddress ? (
                                        <div>
                                            <div className="address-locator__selected-address">
                                                <i className="icon icon-pin-small" />
                                                <p className="body-large">
                                                    {this.createFormattedAddres(
                                                        this.state
                                                            .selectedRecord
                                                    )}
                                                </p>
                                                <button
                                                    type="button"
                                                    className="body-small"
                                                    onClick={
                                                        this
                                                            .handleSearchAgainClick
                                                    }
                                                >
                                                    Search again
                                                </button>
                                            </div>
                                            <div>
                                                <div className="address-locator__save-address-fields">
                                                    <ReactFormField
                                                        type="text"
                                                        name="addressNickname"
                                                        id="addressNickname"
                                                        label="Address Nickname"
                                                        placeholder="e.g. Home"
                                                        required
                                                    />
                                                    <ReactFormField
                                                        type="text"
                                                        name="unitNumber"
                                                        id="unitNumber"
                                                        label="Unit number / level (Optional)"
                                                        placeholder="e.g. Unit 10"
                                                    />
                                                    <ReactFormField
                                                        type="text"
                                                        name="deliveryNotes"
                                                        id="deliveryNotes"
                                                        label="Driver Only Notes (Optional)"
                                                        placeholder="e.g. Beware of dogs"
                                                    />
                                                </div>
                                                <div
                                                    className={`address-locator__submit-container`}
                                                >
                                                    <Button
                                                        type="submit"
                                                        loading={this.state.submitLoading}
                                                        disabled={
                                                            !_.get(
                                                                this,
                                                                "state.selectedRecord.record_id"
                                                            )
                                                        }
                                                    >
                                                        {this.props.addSavedAddress
                                                            ? "Save Address"
                                                            : this.props.editSavedAddress
                                                            ? "Save Changes"
                                                            : "Next"}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    ) : this.props.editSavedAddress &&
                                      this.state.selectedRecord ? (
                                        <>
                                            <div className="address-locator__selected-address">
                                                <i className="icon icon-pin-small" />
                                                <p className="body-large">
                                                    {this.createFormattedAddres(
                                                        this.state
                                                            .selectedRecord
                                                    )}
                                                </p>
                                            </div>
                                            <div className="address-locator__edit-container">
                                                <div className="address-locator__save-address-fields">
                                                    <ReactFormField
                                                        type="text"
                                                        name="addressNickname"
                                                        id="addressNickname"
                                                        label="Address Nickname"
                                                        placeholder="e.g. Home"
                                                        defaultValue={_.get(
                                                            this.state
                                                                .selectedRecord,
                                                            "nickname",
                                                            ""
                                                        )}
                                                        required
                                                    />
                                                    <ReactFormField
                                                        type="text"
                                                        name="unitNumber"
                                                        id="unitNumber"
                                                        label="Unit number / level (Optional)"
                                                        placeholder="e.g. Unit 10"
                                                        defaultValue={_.get(
                                                            this.state
                                                                .selectedRecord,
                                                            "unit_number",
                                                            ""
                                                        )}
                                                    />
                                                    <ReactFormField
                                                        type="text"
                                                        name="deliveryNotes"
                                                        id="deliveryNotes"
                                                        label="Driver Only Notes (Optional)"
                                                        placeholder="e.g. Beware of dogs"
                                                        defaultValue={_.get(
                                                            this.state
                                                                .selectedRecord,
                                                            "delivery_note",
                                                            ""
                                                        )}
                                                    />
                                                </div>
                                                <TextButton
                                                    className={`address-locator__delete-button`}
                                                    onClick={this.props.onDelete}
                                                >
                                                    Delete this address
                                                </TextButton>
                                                <div
                                                    className={`address-locator__submit-container`}
                                                >
                                                    <Button
                                                        type="submit"
                                                        loading={this.state.submitLoading}
                                                        disabled={
                                                            !_.get(
                                                                this,
                                                                "state.selectedRecord.record_id"
                                                            )
                                                        }
                                                    >
                                                        {this.props.addSavedAddress
                                                            ? "Save Address"
                                                            : this.props.editSavedAddress
                                                            ? "Save Changes"
                                                            : "Next"}
                                                    </Button>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <QuickSearch
                                            value={this.state.inputValue}
                                            results={this.state.searchResults}
                                            loading={this.state.searching}
                                            hasResults={this.state.hasResults}
                                            savedLocations={
                                                this.props.addSavedAddress
                                                    ? []
                                                    : this.props.savedAddresses
                                                          .addresses
                                            }
                                            selectedRecordId={
                                                this.state.selectedRecord
                                                    ? this.state.selectedRecord
                                                          .record_id
                                                    : null
                                            }
                                            selectedAddressId={
                                                this.state.selectedRecord
                                                    ? this.state.selectedRecord
                                                          .address_id
                                                    : null
                                            }
                                            submitButton={
                                                <div
                                                    className={`address-locator__submit-container`}
                                                >
                                                    <Button
                                                        type="submit"
                                                        loading={this.state.submitLoading}
                                                        disabled={
                                                            !_.get(
                                                                this,
                                                                "state.selectedRecord.record_id"
                                                            )
                                                        }
                                                    >
                                                        {this.props.addSavedAddress
                                                            ? "Save Address"
                                                            : this.props.editSavedAddress
                                                            ? "Save Changes"
                                                            : "Next"}
                                                    </Button>
                                                </div>
                                            }
                                            onChange={
                                                this.handleSearchInputChange
                                            }
                                            onResultSelect={
                                                this.handleResultSelect
                                            }
                                            onUseLocationClick={
                                                this.handleUseLocationClick
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </ReactForm>
            </div>
        );
    }
}

const mapStateToProps = (state: any): any => {
    const { latitude: lat, longitude: lng } = _.get(
        state,
        "device.geolocation.position.coords",
        {
            latitude: -33.86785,
            longitude: 151.20732
        }
    );
    return {
        savedAddresses: state.savedAddresses,
        device: state.device,
        stores: state.stores,
        userLatLng: { lat, lng },
        session: state.session,
        deliveryTimeFrame:
            state.config.order_delivery.default_expected_time_frame,
        deliveryTimes: {
            from: state.config.order_delivery.hours_range_from,
            to: state.config.order_delivery.hours_range_to,
            increments: state.config.order_delivery.hours_increments,
            offset: state.config.order_delivery.hours_after_now
        }
    };
};

const mapDispatchToProps = {
    getGeoStatus: Actions.device.getGeoStatus,
    setDeliveryLocation: Actions.session.setDeliveryLocation,
    setSelectedStore: Actions.session.setSelectedStore,
    setDeliveryTime: Actions.session.setDeliveryTime,
    setOrderMethod: Actions.session.setOrderMethod,
    closeGlobalModal: Actions.globalModals.closeGlobalModal,
    push: push,
    openAlertModal: Actions.globalModals.openAlertModal,
    closeAlertModal: Actions.globalModals.closeAlertModal
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddressLocator);
